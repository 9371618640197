import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './auth/Login';
import Home from './pages/home/Home';
import './App.css'
import Allorders from './pages/orders/Allorders';
import Track from './components/track/Track';
import Riders from './components/riders/Riders';
import Orders from './components/orders/Orders';
import Stores from './components/stores/Stores';
import Users from './components/users/Users';
import Profile from './components/profile/Profile';
import Reports from './components/reports/Reports';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
         
          <Route path='/' element={<Login />} />
          <Route path='/home' element={<Home />} />
          <Route path='/allorders' element={<Allorders />} />
          <Route path='/track' element={<Track />} />
          <Route path='/riders' element={<Riders />} />
          <Route path='/operations' element={<Orders />} />
          <Route path='/stores' element={<Stores />} />
          <Route path='/users' element={<Users />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/profile' element={<Profile />} />

    

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
