import {React, useState, useEffect} from 'react'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import './sidebar.css'
import Header from '../../components/header/Header';
import GridViewIcon from '@mui/icons-material/GridView';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MopedIcon from '@mui/icons-material/Moped';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dashboard from '../../components/dashboard/Dashboard';
import Orders from '../../components/orders/Orders';
import Stores from '../../components/stores/Stores';
import Users from '../../components/users/Users';
import Riders from '../../components/riders/Riders';
import Track from '../../components/track/Track';
import Reports from '../../components/reports/Reports';
import Profile from '../../components/profile/Profile';
import MenuIcon from '@mui/icons-material/Menu';

export default function Sidebar() {
  const[email, setEmail] = useState('');
  const[username, setUsername] = useState('');
  const[userphone, setUserphone] = useState('');
  const[userrole, setUserrole] = useState('')
  const[password, setPassword] = useState('')
  const[active, setActive] = useState('')

  const navigate = useNavigate();

  Axios.defaults.withCredentials = true;
  let mail = ''
  useEffect(() =>{
    Axios.get('https://portalapi.yunshuglobal.com/api/profile').then((response)=>{
      if(response.data.valid){
          setEmail(response.data.email)
      }
      else{
        //pass
        navigate('/')
      }
    
    })
  },[])

  useEffect(()=>{
      Axios.get(`https://portalapi.yunshuglobal.com/api/getUserdetails?email=${email}`).then((res)=>{
          setUsername(res.data.username)
          setUserphone(res.data.userphone)
          setUserrole(res.data.role);
          setPassword(res.data.password);
          setActive(<Dashboard username={res.data.username} />)

      })

  },[email])

  const getOrders = () =>{
    navigate('/operations')
  }
  const getDashboard = () =>{
    if(userrole == 'Admin'){
      navigate('/home')
    }
    else{

    }

  }
  const getStores = () =>{
    if(userrole == 'Admin'){
      navigate('/stores')
    }
    
  }
  const getUsers = () =>{
    if(userrole == 'Admin'){
      navigate('/users')
    }
   
  }
  const getRiders = () =>{
    if(userrole == 'Admin'){
      navigate('/riders')
    }
    
    
  }
  const getTrack = () =>{
    navigate('/track')
  }
  const getReports = () =>{
    if(userrole == 'Admin'){
      navigate('/reports')
    }
    
  }
  const getProfile = () =>{
    navigate(`/profile`);
   
  }

  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the dropdown menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Functions to handle dropdown item clicks
  const handleItemClick = (item) => {
    console.log(`Item clicked: ${item}`);
    // Add your function logic here
  };

  return (
    <div className='homeContainer'>
    <div className="sidebar">
        <div className='sidebarWrapper'>
          {/* <img src="/assets/yunshulogo.jpg" className='sideLogo' alt="" /> */}
          <h5>Quick Menu</h5>
        
          <hr />
          <div className='phoneMenu'>
          <div style={{ position: 'relative', display: 'inline-block' }}>
      <button onClick={toggleMenu} style={{ padding: '10px', cursor: 'pointer' }}>
        <MenuIcon />
      </button>

      {isOpen && (
        <ul
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            listStyle: 'none',
            padding: '10px',
            margin: 0,
            zIndex: 1,
          }}
        >
          <li className='menulist' onClick={getDashboard}> Dashboard</li>
          <li className='menulist' onClick={getOrders}> Operations</li>
          <li className='menulist' onClick={getStores}> Stores</li>
          <li className='menulist' onClick={getUsers}> Users</li>
          <li className='menulist' onClick={getRiders}> Riders</li>
          <li className='menulist'> Returns</li>
          <li className='menulist'  onClick={getTrack}> Tracking</li>
          <li className='menulist' onClick={getReports}> Reports</li>
          <li className='menulist' onClick={getProfile}> Account</li>
          
        </ul>
      )}
    </div>
          </div>

            <div className="sidebarItems">
                <button onClick={getDashboard}><GridViewIcon className='menuIcons' />Dashboard</button>
                <button onClick={getOrders}><ShoppingCartIcon className='menuIcons' />Operations</button>
                <button onClick={getStores}><StoreIcon className='menuIcons' />Stores</button>
                <button onClick={getUsers}><PeopleAltIcon className='menuIcons' />Users</button>
                <button onClick={getRiders}><MopedIcon className='menuIcons' />Riders</button>
                <button onClick={getRiders}><MopedIcon className='menuIcons' />Returnes & Disputes</button>
                <button onClick={getTrack}><GpsFixedIcon className='menuIcons'/>Tracking</button>
                <button onClick={getReports}><AssessmentIcon className='menuIcons' />Reports</button>
                <button onClick={getProfile}><AccountCircleIcon className='menuIcons' />My Account</button>
            </div>
        </div>
    </div>
   
</div>
  )
}
