import {React,useState,useEffect} from 'react'
import './dashboard.css'
import Axios from 'axios'
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import {Table} from 'reactstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';



export default function Dashboard({username}) {
    const name = `Welcome to Yunshu Africa Limited Dashboard.`
    const [displayText, setDisplayText] = useState('');
    const [index, setIndex] = useState(0);
    const [allOrders, setAllorders] = useState([])
    const [deliveredOrders, setDeliveredorders] = useState([])
    const [cancelledOrders, setCancelledorders] = useState([])
    const [returnedOrders, setReturnedorders] = useState([])
    const [seriesNb, setSeriesNb] = useState(2);
    const [itemNb, setItemNb] = useState(8);
    const [skipAnimation, setSkipAnimation] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7; 
    const totalPages = Math.ceil(allOrders.length / itemsPerPage);
    const [search, setSearch] = useState("")
    const [data, setData] = useState([]);
    const [fortyfive,setFortufive] = useState('');
    const [onehour, setOnehour] = useState('');
    const [onethirtymin, setOnethirtymin] = useState('');
    const [afteronethirty,setAfteronethirty] = useState('');
    const [series, setSeries] = useState([]);
    const [period,setPeriod] = useState('monthly')
    const [tt, setTT] = useState('Monthly orders')
    const navigate = useNavigate();
    const monthLabels = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const [uData, setUData] = useState([]);
    const [xLabels, setXLabels] = useState([]);
  
    useEffect(() => {
      if(period == 'monthly'){
        setTT('Monthly orders')
      Axios.get('https://portalapi.yunshuglobal.com/api/getMonthlyorders')
        .then(response => {
          const { data } = response;
          const orders = data.map(item => item.totalOrders);
          const months = data.map(item => item.month);
          setUData(orders);
          setXLabels(months);
        })
        .catch(error => console.error('Error fetching data:', error));
      }

      if(period == 'week'){
        setTT('Last week orders')
        Axios.get('https://portalapi.yunshuglobal.com/api/getLastweekorders')
        .then(response => {
          const { data } = response;
          const orders = data.map(item => item.totalOrders);
          const days = data.map(item => {
            const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            return dayNames[item.day - 1];
          });

          setUData(orders);
          setXLabels(days);
        })
        .catch(error => console.error('Error fetching data:', error));
      }

      if(period == 'month'){
        setTT('Last month orders')
        Axios.get('https://portalapi.yunshuglobal.com/api/getLastmonthorders')
        .then(response => {
          const { data } = response;
  
          // Extract totalOrders for the chart data
          const orders = data.map(item => item.totalOrders);
          const days = data.map(item => item.day);
  
          // Update state
          setUData(orders);
          setXLabels(days);
        })
        .catch(error => console.error('Error fetching data:', error));
      }
    }, [period]);
    

    useEffect(()=>{
        Axios.get('https://portalapi.yunshuglobal.com/api/getAllorders').then((res)=>{
            setAllorders(res.data)
        })

        Axios.get('https://portalapi.yunshuglobal.com/api/getDeliveredorders').then((res)=>{
            setDeliveredorders(res.data)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/getReturnedorders').then((res)=>{
            setReturnedorders(res.data)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/getCancelledorders').then((res)=>{
            setCancelledorders(res.data)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/getRidergraph').then((res)=>{
          setData(res.data)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/45min').then((res)=>{
          setFortufive(res.data.total)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/1hour').then((res)=>{
          setOnehour(res.data.total)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/130min').then((res)=>{
          setOnethirtymin(res.data.total)
        })
        Axios.get('https://portalapi.yunshuglobal.com/api/after130min').then((res)=>{
          setAfteronethirty(res.data.total)
        })

        Axios.get('https://portalapi.yunshuglobal.com/api/getPermonthorders')
      .then(response => {
        const data = response.data;
        const formattedSeries = formatDataForSeries(data);
        setSeries(formattedSeries);
      })
      .catch(error => console.error('Error fetching data:', error));

    },[])
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDisplayText((prev) => prev + name[index]);
        setIndex((prev) => prev + 1);
      }, 150);
  
      if (index === name.length) {
        clearInterval(interval);
        setTimeout(() => {
          setDisplayText('');
          setIndex(0);
        }, 1500);     
      }
  
      return () => clearInterval(interval);
    }, [index, name]);



    const handleItemNbChange = (event, newValue) => {
        if (typeof newValue !== 'number') {
          return;
        }
        setItemNb(newValue);
      };
      const handleSeriesNbChange = (event, newValue) => {
        if (typeof newValue !== 'number') {
          return;
        }
        setSeriesNb(newValue);
      };

      const highlightScope = {
        highlighted: 'series',
        faded: 'global',
      };
      
      const formatDataForSeries = (data) => {
        const stores = [...new Set(data.map(item => item.store))];
    
        return stores.map(store => {
          const monthlyData = Array(12).fill(0);
          data.filter(item => item.store === store).forEach(item => {
            monthlyData[item.month - 1] = item.order_count;
          });
    
          return {
            label: store,
            data: monthlyData,
            highlightScope: {
              highlighted: 'series',
              faded: 'global',
            },
          };
        });
      };



      const data2 = [
        { id: 0, value: parseInt(fortyfive), label: 'In 45 minutes' },
        { id: 1, value: onehour, label: 'In 60 minutes' },
        { id: 2, value: onethirtymin, label: 'In 1hr 30minutes' },
        { id: 3, value: afteronethirty, label: 'After 1hr 30minutes' },

      ];  


      const currentOrders = allOrders.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
    
      const handleClick = (page) => {
        setCurrentPage(page);
      };

      useEffect(()=>{
        Axios.get(`https://portalapi.yunshuglobal.com/api/searchOrder?search=${search}`).then((res)=>{
            setAllorders(res.data)
        })
      },[search])


  
  const toAllorders =()=>{
    navigate(`/allorders?status=All`);
  }
  const toDelivered =()=>{
    navigate(`/allorders?status=Delivered`);
  }
  const toCancelled =()=>{
    navigate(`/allorders?status=Cancelled`);
  }
  const toReturned =()=>{
    navigate(`/allorders?status=Returned`);
  }



  return (
    <div className='dashboardWrapper'>
        <div className="dashIntro">
            <h4>Hi,{username}</h4>
            <p>*{displayText}</p>
      
        </div>
        <div className="dashTabs">
            <div className='dt'>
            <div className="tab1" onClick={toAllorders}>
                <p>All Orders</p>
                <h4>{allOrders.length}</h4>
            </div>
            <div className="tab2" onClick={toDelivered}>
                <p>Deliverd Orders</p>
                <h4>{deliveredOrders.length}</h4>
            </div>

            </div>
            <div className='dt'>
            <div className="tab3" onClick={toCancelled}>
                <p>Cancelled Orders</p>
                <h4>{cancelledOrders.length}</h4>
            </div>
            <div className="tab4" onClick={toReturned}>
                <p>Returned Orders</p>
                <h4>{returnedOrders.length}</h4>
            </div>

            </div>
          
            
        </div>
        <h4  style={{color:'#3970c3',textAlign:'left',marginLeft:'2%'}}>Performance overview</h4>
        <div className="filterByperiod">
            <FloatingLabel controlId="floatingInputGrid" label="Select period" className="formInput"  >
            <Form.Select className="inputSearch"  aria-label="Floating label select example" onChange={(e)=>{setPeriod(e.target.value)}}  >
                    <option selected disabled>Select period</option>
                    <option value='week'>Last Week</option>
                    <option value='month'>Last month</option>
                    <option value='monthly'>Per month</option>
                    
                </Form.Select>
                        
            </FloatingLabel>

            </div>
        <div className="dashStatistics">
            <div className="dashstatisticsLeft">
                <div className="linegraph">
                <h6>{tt}</h6>
                <LineChart
                    width={400}
                    height={300}
                    series={[{ data: uData, area: true, showMark: false,color:'#3970c3' }]}
                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                    sx={{
                        [`& .${lineElementClasses.root}`]: {
                        display: 'none',
                        },
                    }}
                    />
                </div>

                <div className="linegraph">
                <h6>Overral Orders Per Rider</h6>
                <PieChart
                    series={[
                        {
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        },
                    ]}
                    height={200}
                    />
                </div>

            </div>
            <div className="dashstatisticsRight">
                <div className="linegraph">
                <h6>Monthly Orders Per Store</h6>
                <Box sx={{ width: '100%' }}>
                <BarChart
                  height={300}
                  series={series.slice(0, seriesNb).map(s => ({ ...s, data: s.data.slice(0, itemNb+5) }))}
                />
              </Box>
                </div>

                <div className="linegraph">
                <h6>Overrall Delivery Efficiency</h6>
                
                <PieChart
                    series={[
                        {
                        data:data2,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        innerRadius: 66,
                        outerRadius: 100,
                        paddingAngle: 4,
                        cornerRadius: 5,
                        startAngle: -124,
                        endAngle: 119,
                        cx: 151,
                        cy: 150,
                        },
                    ]}
                    
                    height={200}
                    />
                </div>
            </div>
        </div>
        <div className="dashTable">
            <h6>Recent orders(7 days)</h6>
            <div className="searchContainer">
            <FloatingLabel onChange={(e)=>{setSearch(e.target.value)}} className='formInput'  controlId="floatingInputGrid" label="Search order">
                <Form.Control  className='inputSearch'  type="text" placeholder="Search order" />
            </FloatingLabel>
            </div>
            <hr />
            <Table id="table-to-xls" className="table">
        <thead>
          <tr>
            <th className='tHeading'>#</th>
            <th className='tHeading'>Date</th>
            <th className='tHeading'>Order Number</th>
            <th className='tHeading'>Customer name</th>
            <th className='tHeading'>Customer phone</th>
            <th className='tHeading'>Delivery location</th>
            <th className='tHeading'>Post time</th>
            <th className='tHeading'>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.map((val, index) => {
            const postDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(val.postTime);
            const postTime = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(val.postTime);

            return (
              <tr key={index}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>{postDate}</td>
                <td>{val.orderNumber}</td>
                <td>{val.customerName}</td>
                <td>{val.customerPhone}</td>
                <td>{val.location}</td>
                <td>{postTime}</td>
                <td>{val.status}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
            <div className="pagination">
        <button onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
        </div>

    </div>
  )
}
